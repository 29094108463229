import React, { useEffect, useState } from "react";
import axios from "axios";
import "./App.css";

function Statistics() {
  const [stats, setStats] = useState({
    lastRunDate: "",
    totalAudioJobs: 0,
    totalJobs: 0,
    totalStudiosAudio: 0,
    totalStudios: 0,
  });
  const [studios, setStudios] = useState([]);
  const [filteredStudios, setFilteredStudios] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [changelogEntries, setChangelogEntries] = useState([]);

  const API_BASE_URL = process.env.REACT_APP_API_URL || "https://devbrada.com";

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/stats`)
      .then((response) => setStats(response.data))
      .catch((error) => console.error("Error fetching stats:", error));
  }, [API_BASE_URL]);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/statstudios`)
      .then((response) => {
        setStudios(response.data);
        setFilteredStudios(response.data);
      })
      .catch((error) => console.error("Error fetching studios:", error));
  }, [API_BASE_URL]);

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/changelog`)
      .then((response) => {
        const entries = response.data;
        if (entries.length > 0) {
          const latestDate = entries.reduce((latest, entry) => {
            return new Date(entry.date) > new Date(latest.date) ? entry : latest;
          }).date;

          const filteredEntries = entries.filter(
            (entry) => entry.date.startsWith(latestDate.split("T")[0])
          );
          setChangelogEntries(filteredEntries);
        }
      })
      .catch((error) => console.error("Error fetching changelog:", error));
  }, [API_BASE_URL]);

  useEffect(() => {
    let filtered = studios;
    if (searchQuery) {
      filtered = filtered.filter((studio) =>
        studio.studio_clean.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }
    if (statusFilter) {
      filtered = filtered.filter((studio) => studio.status === parseInt(statusFilter));
    }
    setFilteredStudios(filtered);
  }, [searchQuery, statusFilter, studios]);

  const statusLabels = {
    1: "Active",
    2: "Warning",
    3: "Error",
    4: "Empty",
  };

  const renderStatusIndicator = (status) => {
    switch (status) {
      case 1:
        return <div className="status-indicator green-circle"></div>;
      case 2:
        return <div className="status-indicator yellow-triangle"></div>;
      case 0:
        return <div className="status-indicator red-square"></div>;
      case 3:
        return <div className="status-indicator grey-circle"></div>;
      default:
        return null;
    }
  };

  const clearFilters = () => {
    setSearchQuery("");
    setStatusFilter("");
  };

  return (
    <div className="app-container">
      <div className="header-container">
        <h1 className="title">
          <a href="https://devbrada.com/">devbrada</a>
        </h1>
        <a href="https://buymeacoffee.com/jayn" target="_blank" rel="noopener noreferrer">
          <img src={`${process.env.PUBLIC_URL}/img/bmc-button.png`} alt="Buy Me A Coffee" className="bmc-button" />
        </a>
      </div>

      <p className="job-count">
        Updated: {stats.lastRunDate ? new Date(stats.lastRunDate).toISOString().slice(0, 16).replace("T", " ") + " EST" : "N/A"}
      </p>
      <p className="job-count">
        Audio Jobs: {stats.totalAudioJobs} &nbsp; • &nbsp; Total Jobs: {stats.totalJobs}
      </p>
      <p className="job-count">
        Audio Studios: {stats.totalStudiosAudio} &nbsp; • &nbsp; Total Studios: {stats.totalStudios}
      </p>

      {changelogEntries.length > 0 && (
        <div className="changelog-container">
          <h3>Changelog</h3>
          <ul>
            {changelogEntries.map((entry, index) => (
              <li key={index}>
                [X] {new Date(entry.date).toISOString().split("T")[0]} <span className="changelog-studio">{entry.studio}</span> - {entry.title}
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="filters">
        <input
          type="text"
          placeholder="Search studios..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-bar-stats"
        />
        <select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          className="filter-dropdown"
        >
          <option value="">Status</option>
          <option value="1">Active</option>
          <option value="2">Warning</option>
          <option value="0">Error</option>
          <option value="3">Empty</option>
        </select>
        <button onClick={clearFilters} className="clear-button">
          Clear
        </button>
      </div>

      <div className="studio-grid">
        {filteredStudios.map((studio, index) => {
          const studioImage = studio.studio_clean.replace(/\s+/g, "_") + ".png";
          return (
            <div key={index} className="studio-item">
              <img src={`https://devbrada.com/studios/${studioImage}`} alt={studio.studio_clean} className="studio-logo" loading="lazy" />
              <div className="studio-info">
                {renderStatusIndicator(studio.status)}
                <p className="studio-text">{studio.studio_clean}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Statistics;
