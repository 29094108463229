import React, { useEffect, useState } from "react";
import axios from "axios";
import "./App.css";

function Home() {
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [stats, setStats] = useState({ lastRunDate: "", totalAudioJobs: 0 });
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedStudio, setSelectedStudio] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const API_BASE_URL = process.env.REACT_APP_API_URL || "https://devbrada.com";

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/jobs`)
      .then(response => {
        const sortedJobs = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
        setJobs(sortedJobs);
        setFilteredJobs(sortedJobs);
      })
      .catch(error => console.error("Error fetching jobs:", error));

    axios
      .get(`${API_BASE_URL}/stats`)
      .then(response => setStats(response.data))
      .catch(error => console.error("Error fetching stats:", error));
  }, [API_BASE_URL]);

  useEffect(() => {
    let filtered = [...jobs];
    if (selectedCountry) filtered = filtered.filter(job => job.country === selectedCountry);
    if (selectedStudio) filtered = filtered.filter(job => job.studio === selectedStudio);
    if (selectedCategory) filtered = filtered.filter(job => job.category === selectedCategory);
    if (searchTerm) {
      filtered = filtered.filter(job => job.title.toLowerCase().includes(searchTerm.toLowerCase()));
    }
    setFilteredJobs(filtered);
  }, [selectedCountry, selectedStudio, selectedCategory, searchTerm, jobs]);

  const resetFilters = () => {
    setSelectedCountry("");
    setSelectedStudio("");
    setSelectedCategory("");
    setSearchTerm("");
  };

  return (
    <div className="app-container">
      <div className="header-container">
        <h1 className="title">
          <a href="https://devbrada.com/">devbrada</a>
        </h1>
        <a href="https://buymeacoffee.com/jayn" target="_blank" rel="noopener noreferrer">
          <img src={`${process.env.PUBLIC_URL}/img/bmc-button.png`} alt="Buy Me A Coffee" className="bmc-button" />
        </a>
      </div>
	  
	  <p className="job-count">
        Updated: {stats.lastRunDate ? new Date(stats.lastRunDate).toISOString().slice(0, 16).replace("T", " ") + " EST" : "N/A"}
      </p>
      <p className="job-count">
        Audio Jobs: {stats.totalAudioJobs} &nbsp; • &nbsp; <a href="https://devbrada.com/statistics">[View All Stats]</a>
      </p>

      <div className="filters">
        <input type="text" placeholder="Search job title..." value={searchTerm} onChange={e => setSearchTerm(e.target.value)} className="search-bar" />
        <div className="filter-dropdown-parent">
          <select value={selectedCountry} onChange={e => setSelectedCountry(e.target.value)} className="filter-dropdown">
            <option value="">Country</option>
            {[...new Set(jobs.map(job => job.country))].sort().map((country, index) => (
              <option key={index} value={country}>{country}</option>
            ))}
          </select>
          <select value={selectedStudio} onChange={e => setSelectedStudio(e.target.value)} className="filter-dropdown">
            <option value="">Studio</option>
            {[...new Set(jobs.map(job => job.studio))].sort().map((studio, index) => (
              <option key={index} value={studio}>{studio}</option>
            ))}
          </select>
          <select value={selectedCategory} onChange={e => setSelectedCategory(e.target.value)} className="filter-dropdown">
            <option value="">Category</option>
            {[...new Set(jobs.map(job => job.category))].sort().map((category, index) => (
              <option key={index} value={category}>{category}</option>
            ))}
          </select>
        </div>
        <button onClick={resetFilters} className="clear-button">Clear</button>
      </div>

      <ul className="job-list">
        {filteredJobs.map(job => (
          <li key={job.href} className="job-item">
            <img src={`${process.env.PUBLIC_URL}/studios/${job.studio.replace(/ /g, "_")}.png`} alt={job.studio} className="studio-image" loading="lazy" />
            <div className="job-item-content">
              <div className="job-title-container">
                <a href={job.href} target="_blank" rel="noopener noreferrer" className="job-title">{job.title}</a>
                <p className="job-date">
                  {new Intl.DateTimeFormat("en-US", { month: "short", day: "numeric", year: "numeric" }).format(new Date(job.date))}
                </p>
              </div>
              <p className="job-info">
                <span className="job-studio">{job.studio}</span>&nbsp; • &nbsp;
                {job.city !== "Unknown" && job.city !== "Remote" ? `${job.city}, ` : ""}
                {job.country === "United States" && job.state ? `${job.state}, ` : ""}
                {(job.city === "Unknown" && job.country === "Unknown") || job.city === "Remote" || job.country === "Remote"
                  ? job.country
                  : job.country !== "Unknown" ? job.country : ""}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Home;
